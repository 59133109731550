
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import moment from "moment";

export default defineComponent({
  name: "ReportHRegister",
  components: {},
  setup() {
    let data = reactive<any>({
      formInline: {
        month: "",
      },
      tableData: [],
    });

    const RegisterReportByHospital = () => {
      Api.Report.RegisterReportByHospital(
        moment(data.formInline.month).format("YYYY"),
        moment(data.formInline.month).format("M")
      ).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          var josnReport = JSON.parse(res.Data);
          data.tableData = josnReport;
          console.log(josnReport);
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    onMounted(() => {
      //RegisterAnnualReportByDocking();
    });

    return {
      ...toRefs(data),
      RegisterReportByHospital,
    };
  },
});
